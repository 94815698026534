<template>
    <div class="wrap-dashboard">
        <div class="main-content">
            <div class="content">
                <div class="wrap-download text-right">
                    <button class="btn btn-scondary mb-3" @click="openMenu">Download</button>
                    <div class="menu-download" v-if="toggleDownload">
                        <form action="">
                            <!-- <select name="" id="" v-model="form.depositor">
                  <option value="" selected disabled>Depositor</option>
                  <option value="LP000057">LP000057</option>
                  <option value="LP000003">LP000003</option>
                  <option value="LP000009">LP000009</option>
                  <option value="CP000002">CP000002</option>
                </select> -->
                            <input
                                placeholder="Input start date"
                                type="text"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                id="start-date"
                                v-model="form.startDate"
                                :max="today"
                            />
                            <input
                                placeholder="Input end date"
                                type="text"
                                onfocus="(this.type='date')"
                                onblur="(this.type='text')"
                                id="start-date"
                                v-model="form.endDate"
                                :max="today"
                            />
                            <div v-b-modal.modal-1 class="btn btn-primary btn-sm" type="button">Apply</div>
                        </form>
                    </div>
                    <b-modal size="xl" id="modal-1" title="Download Deposit Confirmation" hide-footer>
                        <div class="btn btn-success" type="button" @click="downloadDeposit">Dowanload</div>
                        <export-deposit :dataDownload="form" ref="downloadmenu" id="downloadMenu"></export-deposit>
                    </b-modal>
                </div>
                <table>
                    <thead>
                        <th>No</th>
                        <th>Metal</th>
                        <!-- <th>Item Description</th> -->
                        <th>Hallmark</th>
                        <th>Serial Number</th>
                        <th>Gross Weight</th>
                        <th>Fineness</th>
                        <!-- <th>Quantity</th> -->
                        <th>Deposit Date</th>
                    </thead>
                    <tbody>
                        <tr v-for="(detail, i) in dataDetail" :key="i">
                            <td>{{ (currentPage - 1) * perPage + (i + 1) }}</td>
                            <td>{{ detail.metal_type ? detail.metal_type : detail.metal.type }}</td>
                            <!-- <td>{{ detail.item_description }}</td> -->
                            <td>{{ detail.hallmark }}</td>
                            <td>{{ detail.serial_number }}</td>
                            <td>{{ detail.weight }}</td>
                            <td>{{ detail.fineness }}</td>
                            <!-- <td>{{ detail.quantity }}</td> -->
                            <td>{{ detail.metal_date | formatIso }}</td>
                        </tr>
                    </tbody>
                </table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="lengthData"
                    :per-page="perPage"
                    @change="handlePageChange"
                    aria-controls="new-table"
                    first-number
                    last-number
                    v-show="lengthData > 10"
                ></b-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
// import ExportDeposit from "../components/export/ExportDeposit.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
    // components: { ExportDeposit },
    data() {
        return {
            dataDetail: [],
            dataHolding: [],
            dataNasabah: [],
            pageOfItems: [],
            isImage: true,
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            metal_id: "",
            withdrawal_id: "",
            status_operator: "",
            offset: 0,
            deposit_status: "",
            label_custom: "",
            label_invoice: "",
            label_manufacturing: "",
            label_vault: "",
            label_clearing: "",
            label_bpbatam: "",
            label_cukai: "",
            loadDataCustomDoc: false,
            loadDataInvoice: false,
            loadDataManu: false,
            loadDataVault: false,
            loadDataClearing: false,
            loadDataBpbatam: false,
            loadDataCukai: false,
            isLoadingPreview: false,
            url: "https://api2-dev.ptkbi.com/v2",
            // url: "https://api2-v2.ptkbi.com/v2",
            role: "",
            dataImage: [],
            numPages: undefined,
            src: null,
            isPdf: false,
            labelPreview: "",
            toggleDownload: false,
            isHideDeposite: false,
            type: "text",
            form: {
                startDate: "",
                endDate: "",
                depositor: "",
            },
            today: "",
        };
    },
    mounted() {
        // this.getData(this.currentPage);
        this.withdrawal_id = this.$route.params.id;
        this.form.depositor = this.$route.params.id;
        this.role = sessionStorage.getItem("role");
        this.today = new Date().toISOString().split("T")[0];
        this.getData(this.currentPage);
    },
    methods: {
        openMenu() {
            this.toggleDownload = !this.toggleDownload;
        },
        handlePageChange(value) {
            this.getData(value);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        getData(value) {
            let offset = (value - 1) * this.perPage;
            console.log(offset);
            let url;
            if (this.role == 2 || this.role == 3) {
                url =
                    "v2/clearing/admin/abx/get/detail/summary_inventory?startDate&endDate&limit&offset=" +
                    offset +
                    "&type=offexchange&depositor=" +
                    this.withdrawal_id;
            } else {
                url = "/v2/clearing/admin/get/detail/deposit/bullion/" + this.withdrawal_id;
            }

            axios.get(url).then((res) => {
                this.dataDetail = res.data.data.body;
                this.lengthData = res.data.data.count;
            });
        },
        downloadDeposit() {
            // let fileName = "deposit-confirmation.pdf";
            let dataId = document.getElementById("downloadMenu");
            let pdf = new jsPDF({
                orientation: "portrait",
                format: "a4",
            });
            html2canvas(dataId, { useCORS: true, scale: 1 }).then((canvas) => {
                var margin = 8;
                var imgWidth = 210 - 2 * margin;
                var imgHeight = (canvas.height * imgWidth) / canvas.width;
                var pageHeight = 295;
                var heightLeft = imgHeight;
                let img = canvas.toDataURL("image/png", 1);

                var position = 1;
                pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(img, "PNG", margin, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                pdf.save("deposit-confirmation");
            });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
    },
};
</script>

<style scoped>
/*.wrap-dashboard {
  min-height: 100vh;
  margin: 0;
  display: grid;
  grid-template-rows: auto 1fr auto;
}*/

.content-data {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.content-data span:first-child {
    width: 115px;
    font-weight: bold;
}

.wrap-left,
.wrap-right {
    width: 49%;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 11%);
    padding: 1.5rem;
    border-radius: 5px;
}

.fa-check {
    color: #28a745;
    cursor: pointer;
}
.fa-times {
    color: #dc3545;
    cursor: pointer;
}

.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}

.form-group {
    margin-bottom: 1.7rem;
}

label {
    display: block;
    width: 100%;
    font-weight: bold;
}

input {
    font-size: 13px;
}

.buuton-add {
    text-align: right;
    font-size: 13px;
}

.content {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 15px;
}

.icon-trade {
    width: 25px;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.filter {
    display: table-cell;
    width: 20%;
    font-size: 12px;
}
.wrap-input {
    display: flex;
}

select {
    width: 130px;
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.status-allocated {
    font-weight: 600;
    color: #2dce89;
}

.status-processed {
    font-weight: 600;
    color: rgb(172, 143, 26);
}

.status-rejected {
    font-weight: 600;
    color: #f5365c;
}

.wrap-download {
    position: relative;
}

.menu-download {
    position: absolute;
    margin-bottom: 1rem;
    background: #fff;
    width: 17rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 1rem;
    font-size: 13px;
    right: 0;
    top: 42px;
}

select,
input {
    border-radius: 3px;
    border: 1px solid #a5a5a5a5;
    padding: 3px;
    width: 100%;
    margin-bottom: 0.5rem;
}

input:last-child {
    margin-bottom: unset;
}
</style>
